import React from 'react';
import './contacts.scss';
import GMap from "../../../common/g_map";
import { GOOGLE_MAP_POINT } from "../../../constants/urls";

export default function Contacts() {
  return <>
    <div className="contacts">
      <div className="contacts-info">
        <div className="header header-contacts"/>
        <div className="contacts-data">
          <a className="contacts-phone" href="tel:+7 (800) 707-53-93">+7 (800) 707-53-93</a>
          <a href="mailto:hi@basis.center" className="contacts-email">hi@basis.center</a>
          <div className="contacts-address">Москва-Сити, Башня «Империя», Пресненская набережная 6c2</div>
          <a className='--flex' href={GOOGLE_MAP_POINT} target="_blank" rel='noopener noreferrer'>
            <div className="contacts-how"/>
          </a>
          <div className="contacts-gap"/>
          <div className="contacts-accreditation">ООО&nbsp;«ЦФТ Базис» осуществляет деятельность в&nbsp;сфере информационных технологий. Согласно Приказу Минцифры от&nbsp;08.10.22 следующие виды деятельности (код): 1.01;&nbsp;2.01</div>
        </div>
      </div>
      <div className="contacts-map">
        <div className="contacts-map__container">
          <GMap/>
        </div>
      </div>
    </div>
  </>
}
