import React from 'react';
import GoogleMap from 'google-map-react';
import mapStyles from './map.json';


const key = 'AIzaSyB_U3BbFFySAjQTzvSAfvkvHUPdK5OAyT0';

class GMap extends React.Component {
  state = {
    center: [
      55.7480273,
      37.5403716
    ]
  };
  renderMarker = (map, maps) => {
    new maps.Marker({
      map: map,
      position: new maps.LatLng(55.7482273, 37.5403716),
      title: 'Империя'
    });
  };

  render() {
    const { desktop = true } = this.props;
    const mapOptions = {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: desktop,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUI: true,
      draggable: desktop,
      styles: mapStyles
    };
    return (
      <GoogleMap
        bootstrapURLKeys={{ key }}
        center={this.state.center}
        zoom={14}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => this.renderMarker(map, maps)}
      />);
  }
}

export default GMap;
