import React, { Component } from 'react';
import './app.scss';

import Scrolling from "./_mobile";
import VacanciesContext from "./context/VacanciesContext";
import Desktop from "./_desktop";
import Tablet from "./_tablet/tablet";

const baseSize = 'base';

const desktop = 1280;
const tablet = 768;
const mobile = 0;

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      vacancies: [],
      size: baseSize,
    };
  }

  componentDidMount = () => {
    fetch('https://api.hh.ru/vacancies?employer_id=3129027')
      .then(response => response.json())
      .then(json => json.items.map(item => ({ id: item.id, name: item.name, url: item.alternate_url })))
      .then(vacancies => {
        const v = vacancies.find(v => v.name === 'Делопроизводитель');
        if (v) {
          v.name = 'Делопроиз-водитель'
        }
        this.setState({ vacancies });
      });


    const TAB_KEY = 9;

    const resize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= desktop) {
        this.setState({ size: desktop });
      } else if (screenWidth >= tablet) {
        this.setState({ size: tablet });
      } else {
        this.setState({ size: mobile });
      }
    };

    window.addEventListener('resize', resize);
    resize();

    document.addEventListener('keydown', function (e) {
      if (e.keyCode === TAB_KEY) {
        e.preventDefault();
      }
    });
  };

  render() {
    const { size, vacancies } = this.state;

    let content;
    switch (size) {
      case desktop:
        content = <Desktop/>;
        break;
      case tablet:
        content = <Tablet/>;
        break;
      default:
        content = <Scrolling/>;
    }

    return (
      <div className="app">
        <VacanciesContext.Provider value={vacancies}>
          {content}
        </VacanciesContext.Provider>
      </div>
    );
  }
}

export default App;
