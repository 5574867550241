import React from 'react';
import './mission.scss';

export default function Mission() {
  return (
    <div className="m-mission m-block" id="mission">
      <div className="m-mission__text">
        <div className="_text">Наша долгосрочная стратегия в&nbsp;создании инновационных финтех продуктов. Финансовые
          технологии окружают любого жителя России и&nbsp;являются фундаментальными для всех сфер жизни.
          <br/>
          <br/>
          Мы&nbsp;любим выстраивать партнерские модели взаимодействия с&nbsp;потребителями и&nbsp;зарабатываем только
          вместе с&nbsp;вами. Главный наш актив&nbsp;&mdash; это люди, которые создают добавленную стоимость, поэтому
          мы&nbsp;всегда будем инвестировать в&nbsp;их&nbsp;развитие.
        </div>
      </div>
      <div className="m-mission__sign">
      </div>
      <div className="m-mission__ceo"/>
    </div>
  );
}
