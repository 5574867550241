import React from 'react';
import './mission.scss';

class Mission extends React.Component {
  render() {
    return <>
      <div className="header header-mission"/>
      <div className="mission">
        <div className="mission-text">
          Наша долгосрочная стратегия в&nbsp;создании инновационных финтех продуктов. Финансовые технологии окружают
          любого жителя России и&nbsp;являются фундаментальными для всех сфер жизни.
          <br/>
          <br/>
          Мы&nbsp;любим выстраивать партнерские модели взаимодействия с&nbsp;потребителями и&nbsp;зарабатываем только
          вместе с&nbsp;вами. Главный наш актив&nbsp;&mdash; это люди, которые создают добавленную стоимость, поэтому
          мы&nbsp;всегда будем инвестировать в&nbsp;их&nbsp;развитие.
        </div>
        <div className="mission-sign">
        </div>
      </div>
      <div className="mission-ceo"/>
    </>
  }
}

export default Mission;
