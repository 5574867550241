import React from 'react';
import './vacancies.scss';
import VacanciesContext from "../../../context/VacanciesContext";
import formatVacancyDescription from "../../../common/format_vacancy_description";
import VacancyView from "./vacancy_view";

class Vacancies extends React.Component {
  state = {
    isVacancyVisible: false,
    vacancy: {}
  };

  componentDidMount() {
    const WIDTH_STATIC = 68 * 3 + 100 * 2 - 10;
    const vacanciesList = document.querySelector('.vacancies-list');
    window.addEventListener('resize', resize);

    function resize() {
      vacanciesList.style.minWidth = window.innerWidth - WIDTH_STATIC + 'px';
    }

    resize();
  }

  showVacancy = async (id) => {
    const vacancy = await formatVacancyDescription(id);
    this.setState({
      isVacancyVisible: true, vacancy
    });
  };

  closeVacancy = () => this.setState({ isVacancyVisible: false });

  render() {
    const { isVacancyVisible, vacancy } = this.state;
    return <>
      <div className="header header-vacancies"/>
      <div className="vacancies">
        <div className="vacancies-text">Мы&nbsp;всегда ищем талантливых ребят. Если ты&nbsp;хочешь делать интересные
          проекты с&nbsp;крутой командой, присылай краткий рассказ о&nbsp;себе и&nbsp;не&nbsp;забудь прикрепить резюме.
        </div>
        <a href="mailto:hi@basis.center" className="hrorbitacenter">hi@basis.center</a>
        <VacanciesContext.Consumer>
          {
            vacancies => (<div className="vacancies-list">
              {vacancies.map(v => (
                <div key={v.url} className="vacancies-offer"
                     onClick={() => {
                       this.showVacancy(v.id);
                     }}>{v.name}</div>
              ))}
            </div>)
          }
        </VacanciesContext.Consumer>
        {isVacancyVisible && (<VacancyView vacancy={vacancy} closeVacancy={this.closeVacancy}/>)}
      </div>
    </>
  }
}

export default Vacancies;