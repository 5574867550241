import React from 'react';
import './left-content.scss';
import Registration from "./blocks/registration";
import Platform from "./blocks/platform";
import Documents from "./blocks/documents";
import DeveloperSystem from './blocks/developer-system'
import Lk from "./blocks/lk";
import Interactive from "./blocks/interactive";

class LeftContent extends React.Component {
  componentDidMount() {
    const leftContent = document.querySelector('.left-content');
    window.addEventListener('resize', resize);

    function resize() {
      leftContent.style.minWidth = window.innerWidth * 0.45 + 'px';
    }

    resize();
  }

  render() {
    const { type } = this.props;
    return <div className={`left-content ${type}`}>
      <Documents/>
      <Platform/>
      <Registration/>
      <DeveloperSystem/>
      <Lk/>
      <Interactive/>
    </div>;
  }
}

export default LeftContent;
