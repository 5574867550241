export const menu = [{
  url: 'mission',
  name: 'Миссия',
}, {
  url: 'vacancies',
  name: 'Вакансии',
}, {
  url: 'contacts',
  name: 'Контакты',
}];
