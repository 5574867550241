import React from 'react';
import './vacancies.scss';
import VacanciesContext from "../context/VacanciesContext";
import formatVacancyDescription from "../common/format_vacancy_description";
import MobileVacancyView from "../common/mobile_vacancy_view";

class Vacancies extends React.Component {
  state = {
    isVacancyVisible: false,
    vacancy: {}
  };
  showVacancy = async (id) => {
    const vacancy = await formatVacancyDescription(id);
    this.setState({
      isVacancyVisible: true, vacancy
    });
  };

  closeVacancy = () => this.setState({ isVacancyVisible: false });

  render() {
    const { isVacancyVisible, vacancy } = this.state;
    return (
      <div className="t-vacancies">
        <div className="t-vacancies__id" id='vacancies'/>
        <div className="t-vacancies__text">
          Мы&nbsp;всегда ищем талантливых ребят. Если ты&nbsp;хочешь делать интересные проекты
          с&nbsp;крутой командой, присылай краткий рассказ о&nbsp;себе и&nbsp;не&nbsp;забудь прикрепить резюме.
        </div>
        <a href="mailto:hi@basis.center" className="t-hrorbitacenter">hi@basis.center</a>
        <VacanciesContext.Consumer>
          {
            vacancies => (<div className="t-vacancies-list">
              {vacancies.map(v => (
                <div key={v.url} className="t-vacancies-offer"
                     onClick={() => {
                       this.showVacancy(v.id);
                     }}>{v.name}</div>
              ))}
            </div>)
          }
        </VacanciesContext.Consumer>
        {isVacancyVisible && (<MobileVacancyView vacancy={vacancy} closeVacancy={this.closeVacancy}/>)}
      </div>
    );

  }
}

export default Vacancies;