import React from 'react';
import ListOfPartners from "../common/list_of_partners";
import { textSlides } from '../constants/text-slides';

export default function Basis() {
  return (
    <>
      <div className="t-basis-welcome">
        <div className="t-basis-welcome__text">Цифровые решения для&nbsp;финансового сектора</div>
        <div className="t-basis__partners"/>
        <div className="t-basis__partners-list">
          <ListOfPartners/>
        </div>
        <a href="#products"><div className="t-basis__products"/></a>
      </div>
      {textSlides.map(({className, title, description, linkTo}, index) => (
        <div className={`t-product t-${className}`} key={index}>
          {index === 0 &&
            <div className="t-product__id" id='products'/>
          }
          <div className={`t-product__image t-${className}__image`}/>
          <div className="t-product__text">
            <div className="t-product__text_header">{title}</div>
            <div className="t-product__text_content">{description}</div>
            {linkTo &&
              <a href={linkTo} target="_blank" rel='noopener noreferrer' className="link-to-service">Подробнее</a>
            }
          </div>
        </div>
      ))}
    </>);
}
