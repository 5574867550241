import React from 'react';

import { Motion, spring } from 'react-motion';

class VacancyView extends React.Component {

  constructor(props) {
    super(props);
    this.view = React.createRef();
  }

  componentDidMount() {
    this.view.current.addEventListener('click', e => {
      if (e.target === this.view.current) {
        this.props.closeVacancy();
      }
    })
  }

  render() {
    const { vacancy, closeVacancy } = this.props;
    return (
      <div className="vacancies-view" ref={this.view}>
        <Motion
          defaultStyle={{ opacity: 0.2 }}
          style={{ opacity: spring(1) }}>
          {style => {
            return <div className="vacancies-view__container" style={{ opacity: style.opacity }}>
              <div className="vacancies-view__header">
                <div className="vacancies-view__header_text">{vacancy.name}</div>
                <div className="vacancies-view__close" onClick={closeVacancy}/>
              </div>
              <div className="vacancies-view__description" dangerouslySetInnerHTML={{ __html: vacancy.description }}>
              </div>
              <a href={`mailto:hi@basis.center?subject=Отклик на вакансию "${vacancy.name}"`}>
                <div className="vacancies-view__btn"/>
              </a>
            </div>
          }
          }
        </Motion>
      </div>
    );
  }
}

export default VacancyView;