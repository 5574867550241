import React from 'react';
import './scrolling.scss';
import Basis from "./basis";
import Mission from "./mission";
import Vacancies from "./vacancies";
import Contacts from "./contacts";
import { menu } from '../constants/objects';

class Scrolling extends React.Component {
  state = {
    menuIsVisible: false,
  };
  setMenuVisibility = menuIsVisible => this.setState({ menuIsVisible });

  render() {
    const { menuIsVisible } = this.state;
    const classNameBack = ["m-base__back", menuIsVisible ? "--active" : ""].join(' ');

    return (
      <div className="m-base">
        <div className={classNameBack} onClick={() => this.setMenuVisibility(false)}/>
        <nav className='m-base__nav'>
          <input type="checkbox" id="m-menu" checked={menuIsVisible} onChange={() => {
          }}/>
          <label htmlFor="m-menu" onClick={() => this.setMenuVisibility(!menuIsVisible)}/>
          <div className='m-base__nav_back'>
            <a href="#basis" onClick={() => this.setMenuVisibility(false)}>
              <div className="m-base__header"/>
            </a>
          </div>

          <ul className="nav-links">
            {menu.map(m => (
              <li key={m.url} onClick={() => this.setMenuVisibility(false)}><a href={'#' + m.url}>{m.name}</a></li>))}
          </ul>
        </nav>
        <div className="m-base__content">
          <Basis/>
          <Mission/>
          <Vacancies/>
          <Contacts/>
        </div>
      </div>
    );
  }

}

export default Scrolling;