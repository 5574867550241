import React from 'react';

import './basis.scss';
import ListOfPartners from "../common/list_of_partners";
import { textSlides } from '../constants/text-slides';

export default function Basis() {
  return <div className="m-block m-basis" id="basis">
    <div className="m-basis__text"/>
    <div className="m-basis__partners"/>
    <div className="m-basis__partners-list">
      <ListOfPartners/>
    </div>
    <div className="m-basis__products"/>
    {textSlides.map(({className, title, description, linkTo}, index) => (
      <div className={`m-products m-${className}`} key={index}>
        <div className={`m-${className}__logo --logo`}/>
        <div className={`m-${className}__text --text`}>
          <div className="-header">{title}</div>
          <div className="-content">{description}</div>
          {linkTo &&
            <div className="centered-text">
              <a href={linkTo} target="_blank" rel='noopener noreferrer' className="link-to-service">Подробнее</a>
            </div>
          }
        </div>
      </div>
    ))}
  </div>;
}
