import React from 'react';

class Interactive extends React.Component {
  constructor(props) {
    super(props);
    this.state = { squares: Array.from({ length: 8 }, _ => Array.from({ length: 7 })) };
  }


  componentDidMount() {
    document.addEventListener('mousemove', (e) => {
      const sqrs = document.querySelectorAll('.square');

      const mouseX = e.pageX;
      const mouseY = e.pageY;

      sqrs.forEach(sqr => {
        const sqrX = sqr.offsetLeft + 20;
        const sqrY = sqr.offsetTop + 20;

        const diffX = mouseX - sqrX;
        const diffY = mouseY - sqrY;

        const radians = Math.atan2(diffY, diffX);

        const angle = radians * 180 / Math.PI;

        sqr.style.transform = `rotate(${angle}deg)`;
      });

    });
  }

  render() {
    const { squares } = this.state;
    return (
      <div className="--content --interactive">
        <div className="wrapper-square">
          {squares.map((s, i) => s.map((_, _i) => (
              <div className="square" key={`${i}_${_i}`}/>
            )
          ))}
        </div>
      </div>
    );
  }

}

export default Interactive;
