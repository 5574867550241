export default function getVacancy(id) {
  return fetch(`https://api.hh.ru/vacancies/${id}`)
    .then(response => response.json())
    .then(vacancy => {
      return {
        name: vacancy.name,
        description: vacancy.description,
      };
    })
}
