import React from 'react';
import SmoothScroll from 'smooth-scroll';
import './basis.scss';
import { menu } from '../constants/objects';
import Basis from "./basis";
import Mission from "./mission";
import Vacancies from "./vacancies";
import Contacts from "./contacts";

const _menu = [{
  url: 'products',
  name: 'Наши продукты',
}].concat(menu);

class Tablet extends React.Component {
  constructor(props) {
    super(props);
    this.view = React.createRef();
  }

  componentDidMount() {

    new SmoothScroll('a[href*="#"]', {
      speed: 300
    });

    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const node = document.querySelector('.t-basis-welcome');
    if (node) {
      node.style.height = document.querySelector('html').clientHeight + 'px';
    }
  };

  render() {
    return (
      <div className='t-basis' id='basis' ref={this.view}>
        <div className="t-basis-header">
          <a href="#basis">
            <div className="t-basis-logo"/>
          </a>
          <div className="t-basis-menu">
            {_menu.map(m => (<a key={m.url} href={'#' + m.url}>{m.name}</a>))}
          </div>
        </div>
        <Basis/>
        <Mission/>
        <Vacancies/>
        <Contacts/>
      </div>
    );
  }
}

export default Tablet;