import React from 'react';
import {
  developerSystem,
  documents,
  interactive,
  lk,
  platform,
  registration,
} from "../constants/types";
import Mission from "./slides/mission";
import Vacancies from "./slides/vacancies";
import Contacts from "./slides/contacts";
import Basis from "./slides/basis";
import LeftContent from "./left-content";

class Desktop extends React.Component {
  mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      slides: [
        {
          comp: <Basis changeBlock={this.changeBlock}/>,
          cl: "slide-basis",
        },
        {
          comp: <Mission/>,
          cl: "slide-mission",
        },
        {
          comp: <Vacancies/>,
          cl: "slide-vacancies",
        },
        {
          comp: <Contacts/>,
          cl: "slide-contacts",
        },
      ],
      activeIndex: 0,
      basis: [
        {
          type: interactive,
        },
        {
          type: lk,
        },
        {
          type: developerSystem
        },
        {
          type: registration,
        },
        {
          type: platform,
        },
        {
          type: documents,
        },
      ],
      activeBasis: 0,
    };
    this.mounted = true;
  }

  changeBlock = activeBasis => {
    if (this.mounted) {
      this.setState({ activeBasis });
    }
  };
  chooseSlide = index => {
    if (this.mounted) {
      this.setState({ activeIndex: index });
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    let { activeIndex, basis, activeBasis } = this.state;
    activeBasis < basis.length || (activeBasis = basis.length - 1);
    const slides = this.state.slides.map(({ comp, cl }, i) => {
      const classNames = ["flexbox-slide", cl];
      if (i === activeIndex) {
        classNames.push('active');
      }
      return <div key={i} className={classNames.join(' ')} onClick={() => this.chooseSlide(i)}>
        {comp}
      </div>;
    });
    const baseMediaClasses = ["base__media"];
    if (activeIndex) {
      baseMediaClasses.push("mini");
    }
    return (<div className="base">
      <div className={baseMediaClasses.join(" ")} onClick={() => this.chooseSlide(0)}>
        <LeftContent type={basis[activeBasis].type}/>
      </div>
      <div className="base__slides">
        <div className="flexbox-slider">
          {slides}
        </div>
      </div>
    </div>);
  }
}

export default Desktop;
