import React from 'react';
import { developerSystem, documents, lk, platform, registration } from './types';

export const textSlides = [
  {
    className: lk.replace('--', ''),
    title: 'Личный кабинет b2c-заёмщика',
    description: `Банковский продукт для подачи заявок на кредит, получения одобрения, выбора недвижимости, страхования
    и проведения сделок онлайн. Экономия на операционных затратах, повышение уровня конверсий
    и автоматизированный контроль сделок на всех этапах.`,
    linkTo: 'https://www.behance.net/gallery/106563165/Digital-Mortgage'
  },
  {
    className: developerSystem.replace('--', ''),
    title: 'Экосистема для застройщиков',
    description: <>
      Весь клиентский путь покупки недвижимости в одной системе. В экосистему входят сервисы:
      <ul>
        {['Каталог', 'Регсервис', 'Бронирование', 'Ключи', 'Ипотека', 'Сделка без риска', 'Документы', 'Личный кабинет'].map((el, index) => (
          <li key={index}>
            {el}
          </li>
        ))}
      </ul>
    </>,
    linkTo: 'https://iflat.io/?utm_source=basiscenter&utm_medium=link'
  },
  {
    className: registration.replace('--', ''),
    title: 'Электронная регистрация сделок с недвижимостью',
    description: `Сервис для моментальной подачи документов на регистрацию в Росреестр. Все
    участники сделки получают зарегистрированные электронные копии документов в личном кабинете.`
  },
  {
    className: platform.replace('--', ''),
    title: 'Цифровая ипотечная платформа',
    description: `Универсальный инструмент для профессионалов рынка недвижимости. Выбирайте лучшее
    предложение, проводите сделку и получайте официальный cashback с уплатой всех налогов.
    Мы берем на себя автоматизацию процессов и интеграцию с кредиторами.`,
    linkTo: 'https://ipoteka.center'
  },
  {
    className: documents.replace('--', ''),
    title: 'Распознавание документов',
    description: `Автоматическое распознавание, верификация и миграция любых клиентских данных
    с бумажных носителей в ПО партнёра.`,
    linkTo: 'https://doc.basis.center/'
  }
]
