import React from 'react';
import './contacts.scss';
import GMap from "../common/g_map";
import { GOOGLE_MAP_POINT } from "../constants/urls";

export default function Contacts() {
  return (
    <div className="m-block m-contacts" id='contacts'>
      <a className="m-contacts__phone" href="tel:+7 (800) 707-53-93">+7 (800) 707-53-93</a>
      <a href="mailto:hi@basis.center" className="m-hibasiscenter">hi@basis.center</a>
      <div className="m-contacts__address">Москва-Сити, Башня «Империя», Пресненская набережная 6c2</div>
      <a className='--flex' href={GOOGLE_MAP_POINT} target="_blank" rel='noopener noreferrer'>
        <div className="m-contacts__how"/>
      </a>
      <div className="m-contacts__accreditation">ООО&nbsp;«ЦФТ Базис» осуществляет деятельность в&nbsp;сфере информационных технологий. Согласно Приказу Минцифры от&nbsp;08.10.22 следующие виды деятельности (код): 1.01;&nbsp;2.01</div>
      <div className="m-contacts-map">
        <div className="m-contacts-map__container">
          <GMap desktop={false}/>
        </div>
      </div>
    </div>
  );
}
