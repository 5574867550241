import React from 'react';

class MobileVacancyView extends React.Component {
  constructor(props) {
    super(props);
    this.view = React.createRef();
  }

  scrollListener = () => {
    const header = this.view.current.querySelector('.m-vacancies-view__header');
    const top = this.view.current.querySelector('.m-vacancies-view__top');
    const scrollTop = this.view.current.scrollTop;
    if (scrollTop >= 50) {
      header.classList.contains("--fixed") || (header.classList.add("--fixed"));
    } else {
      header.classList.remove("--fixed");
    }
    if (header.clientHeight > 40) {
      top.classList.contains("--expanded") || (top.classList.add("--expanded"));
    } else {
      top.classList.remove("--expanded");
    }
  };

  componentDidMount() {
    this.view.current.addEventListener('scroll', this.scrollListener);
  }

  componentWillUnmount() {
    this.view.current.removeEventListener('scroll', this.scrollListener);
  }

  render() {
    const { vacancy, closeVacancy } = this.props;
    return (
      <div className='m-vacancies-view' ref={this.view}>
        <div className="m-vacancies-view__top">
          <div className="m-vacancies-view__top_close" onClick={closeVacancy}/>
        </div>
        <div className="m-vacancies-view__header">{vacancy.name}</div>
        <div className="m-vacancies-view__description" dangerouslySetInnerHTML={{ __html: vacancy.description }}/>
        <a href={`mailto:hi@basis.center?subject=Отклик на вакансию "${vacancy.name}"`}>
          <div className="m-vacancies-view__btn"/>
        </a>
      </div>
    );
  }
}

export default MobileVacancyView;