import React from 'react';
import { textSlides } from '../../../constants/text-slides';
import './basis.scss';

class Basis extends React.Component {
  TO = null;
  ref= null;

  componentDidMount() {
    const { changeBlock } = this.props;
    let currentBlock = 0;
    const paddingTop = 100;
    const basisMarginLeft = 60;
    const basis = document.querySelector('.basis');
    const _products = document.querySelector('._products');
    let heightBasis = basis.clientHeight - paddingTop;
    const leftContent = document.querySelector('.left-content');

    const resize = () => {
      heightBasis = basis.clientHeight;
      const shift = -basisMarginLeft - parseFloat(leftContent.style.minWidth);
      basis.style.left = shift + 'px';
      basis.style.paddingLeft = -shift + 'px';
    };
    const _changeBlock = () => {
      let detectBlock = 0;

      if (!this.ref){
        return;
      }

      for (let i=0;i<this.ref.children.length; i++){
        const { top } = this.ref.children[i].getBoundingClientRect();

        if (top < 500){
          detectBlock = i
        }
      }

      if (typeof changeBlock === 'function' && detectBlock !== currentBlock) {
        currentBlock = detectBlock;
        clearInterval(this.TO);
        this.TO = setTimeout(() => {
          changeBlock(currentBlock)
        }, 200);
      }
    };
    basis.addEventListener('scroll', function (e) {
      _changeBlock()
    });
    _products.addEventListener('click', function () {
      requestAnimationFrame(step);
    });

    function step() {
      if (basis.scrollTop < heightBasis - paddingTop) {
        basis.scrollTop += Math.min(32, heightBasis - paddingTop - basis.scrollTop);
        requestAnimationFrame(step);
      }
    }

    window.addEventListener('resize', function () {
      resize();
      _changeBlock();
    });
    resize();
  }

  render() {
    return <>
      <div className="header header-basis"/>
      <div className="basis" ref={(ref) => this.ref = ref}>
        <div className="basis-content --interactive">
          <div className="_header"/>
          <div className="_partners"/>
          <div className="_partners-list">
          </div>
          <div className="_products"/>
        </div>
        {textSlides.map(({className, title, description, linkTo}, index) => (
          <div className={`basis-content --${className}`} key={index}>
            <div className="_logo"/>
            <div className="_header">{title}</div>
            <div className="_text">
              {description}
            </div>
            {linkTo &&
              <a href={linkTo} target="_blank" rel='noopener noreferrer' className="link-to-service">Подробнее</a>
            }
          </div>
        ))}
      </div>
    </>
  }
}

export default Basis;
